:root {
  --t-border-radius: 12px;
  --t-color-blue-link: rgb(0, 102, 204);
  --t-color-blue-light: rgb(76, 166, 255);
  --t-color-blue: rgb(0, 113, 227);
  --t-content-width-sm: 550px;
  --t-gap: 10px;
}

* {
  box-sizing: border-box;
}

body {
  background: #efefef;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.625;
  font-size: 14px;
}

a {
  color: var(--t-color-blue-link);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

p:not(:last-child) {
  margin-bottom: 10px;
}

strong {
  font-weight: 800;
}

.container {
  margin-left: auto;
  margin-right: auto;
  max-width: var(--t-content-width-sm);
  padding: 25px;
  width: 100%;
}

.hidden {
  visibility: hidden;
}

.error {
  border-color: #d01c1c;
}

.success {
  border-color: rgb(28, 143, 49);
}

.row {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
